<template>
    <template-card-base
        :receipt="receipt"
        :orderStatusLabel="orderStatusLabelById(receipt.orderStatus)"
        :orderTypeLabel="orderTypeLabelById(receipt.orderType)"
        :platformTypeLabel="platformLabelById(receipt.platformType)"
        :waypointLabel="waypointLabel"
        :selected="selected"
        :show-actions="showActions"
        :waypointId="waypointId"
        :platform-types="platformTypes"
        :order-types="orderTypes"
        v-bind="$attrs"
        v-on="$listeners"
    />
</template>

<script>
import TemplateCardBase from "components/receipts/TemplateCardBase";
import {mapGetters} from "vuex";
import metadata from "mixins/receiptsMetadata";

export default {
    name: "TemplateCardWrapper",
    components: {TemplateCardBase},
    props: {
        id: {
            type: String,
            required: true,
        },
        selected: {
            type: Boolean,
            required: true,
        },
        showActions: {
            type: Boolean,
            required: true,
        },
        waypointId: {
            type: Number,
            required: true,
        }
    },
    mixins: [metadata],
    computed: {
        ...mapGetters("ReceiptsStore", [
            "receiptTemplates",
        ]),
        ...mapGetters("AirportStore", ["locations"]),
        receipt() {
            return this.receiptTemplates.find(x => x.templateID === this.id);
        },
        waypointLabel() {
            const waypoint = this.locations.find(x => x.waypointID === this.waypointId);
            return waypoint ? `${waypoint.name}-${waypoint.terminal}, ${waypoint.airportIdent}` : ``;
        },
    },
    methods: {
    }
}
</script>

<style scoped>

</style>
