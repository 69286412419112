<template>
    <v-card
        :class="{
            'ma-3': true,
            'template-card': true,
            'template-card--selected':  selected,
        }"
        elevation="0"
        tile
        v-bind="$attrs"
    >
        <div class="d-flex align-center pl-5 pr-3 template-card__title border-bottom">
            <div>
                <v-icon color="#84B7D3">message</v-icon><span class="ml-2">{{orderStatusLabel}}</span>
            </div>
            <v-spacer></v-spacer>
            <v-checkbox
                v-if="showActions"
                v-model="selectedInternal"
                color="#4A90E2"
                class="shrink ma-0"
                hide-details
                dense
                size="sm"
            />
        </div>

        <div class="pl-8 pr-8 pt-5 pb-5 border-bottom">
            <v-row class="template-card__platforms">
                <v-col class="pa-0 font-weight-bold" md="2">Platforms</v-col>
                <v-col class="pa-0" md="10">
                    <v-chip
                        v-for="platform of platformTypeOptions"
                        :key="platform.id"
                        :color="platform.isSelected ? '#439ED5' : '#ccc'"
                        class="pl-1 pr-1 ml-1"
                        label
                        small
                        dark
                    >
                        {{platform.text}}
                    </v-chip>
                </v-col>
            </v-row>
            <v-row class="template-card__order-type pt-1">
                <v-col class="pa-0 font-weight-bold" md="2">Order type</v-col>
                <v-col class="pa-0" md="10">
                    <v-chip
                        v-for="orderType of orderTypeOptions"
                        :key="orderType.id"
                        :color="orderType.isSelected ? '#679436' : '#ccc'"
                        class="pl-1 pr-1 ml-1"
                        label
                        small
                        outlined
                    >
                        {{orderType.text}}
                    </v-chip>
                </v-col>
            </v-row>
        </div>
        <div class="pl-8 pr-8 pt-7 pb-5">
            <v-row class="template-card__text">
                <v-col class="pa-0">
                    <v-expansion-panels accordion>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                Header logo
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <span v-html="receipt.headerLogoHtml" />
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header>Template file: {{receipt.templateFile}}</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <iframe width="100%" height="300px" :src="'https://grabmobilestagev2.com/cursusairportinformation/EmailTemplate/' + receipt.templateFile" frameborder="0"/>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header>Thank you message</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <span v-html="receipt.thankYouMessageHtml"></span>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header>Email subject template</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <span v-html="receipt.emailSubjectTemplate"></span>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header>Template description</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <span v-html="receipt.templateDescription"></span>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </div>

        <v-card-actions class="d-flex pr-5 pl-5 pt-3 align-center mt-auto border-top">
            <v-spacer/>
            <div
                v-if="receipt.isDefaultTemplate"
                class="template-card__actions grey--text text-uppercase pr-2">
                default
            </div>
            <div v-else-if="restoreConfirmation">
                <span class="template-card__reset-confirmation">
                    Are you sure?
                </span>
                <v-btn
                    @click="reset()"
                    x-small
                    text
                    color="#679436"
                >
                    <v-icon x-small>check</v-icon>
                </v-btn>
                <v-btn
                    @click="restoreConfirmation=false"
                    x-small
                    text
                    color="#f00"
                >
                    <v-icon x-small>close</v-icon>
                </v-btn>
            </div>
            <v-btn
                v-else
                @click="restoreConfirmation = true"
                x-small
                text
                color="#84B7D3"
            >
                restore to default <v-icon x-small>restore</v-icon>
            </v-btn>
            <v-divider vertical class="mt-1 mb-1 ml-1 mr-1" />
            <v-btn
                color="#679436"
                x-small
                text
                @click="edit()"
            >
                <v-icon small>edit</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "TemplateCardBase",
    props: {
        receipt: {
            type: Object,
            required: true,
        },
        orderStatusLabel: {
            type: String,
            required: true,
        },
        orderTypeLabel: {
            type: String,
            required: true,
        },
        platformTypeLabel: {
            type: String,
            required: true,
        },
        waypointLabel: {
            type: String,
            required: false,
            default: null,
        },
        selected: {
            type: Boolean,
            required: true,
        },
        showActions: {
            type: Boolean,
            required: true,
        },
        orderTypes: {
            type: Object,
            required: true,
        },
        platformTypes: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            chipAttrs: {
                label: true,
                outlined: true,
                'x-small': true,
            },
            selectedInternal: this.selected,
            restoreConfirmation: false,
        };
    },
    methods: {
        edit() {
            this.$emit('edit', { id: this.receipt.templateID });
        },
        restore() {
            this.$emit('restore', { id: this.receipt.templateID });
        },
        onSelectedChange(value) {
            this.$emit('selected-change', {
                templateID: this.receipt.templateID,
                waypointID: this.waypointId,
                selected: value,
            });
        },
    },
    watch:{
        selectedInternal(value) {
            this.onSelectedChange(value);
        },
        selected(value) {
            this.selectedInternal = value;
        }
    },
    computed: {
        platformTypeOptions() {
            return Object.entries(this.platformTypes).map(([id, text]) => ({
                id,
                text,
                isSelected: this.receipt.platformType === 0 || this.receipt.platformType == id,
            }));
        },
        orderTypeOptions() {
            return Object.entries(this.orderTypes).map(([id, text]) => ({
                id,
                text,
                isSelected: this.receipt.orderType === 0 || this.receipt.orderType == id,
            }));
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../scss/variables";
@import "../../scss/mixins";
.border-bottom {
    border-bottom: 1px solid #e4e4e4;
}
.border-top {
    border-top: 1px solid #e4e4e4;
}
.template-card {
    border: 1px solid #E4E4E4;
    &--selected {
        border: 2px solid #679436;
    }
    &__title {
        font-size: fz(md);
        height: 42px;
    }
    &__platforms, &__order-type, &__text {
        font-size: fz(sm);
    }
    &__actions, &__languages-title, &__reset-confirmation {
        font-size: fz(xs);
    }
}
</style>
