var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    _vm._b(
      {
        class: {
          "ma-3": true,
          "template-card": true,
          "template-card--selected": _vm.selected,
        },
        attrs: { elevation: "0", tile: "" },
      },
      "v-card",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex align-center pl-5 pr-3 template-card__title border-bottom",
        },
        [
          _c(
            "div",
            [
              _c("v-icon", { attrs: { color: "#84B7D3" } }, [
                _vm._v("message"),
              ]),
              _c("span", { staticClass: "ml-2" }, [
                _vm._v(_vm._s(_vm.orderStatusLabel)),
              ]),
            ],
            1
          ),
          _c("v-spacer"),
          _vm.showActions
            ? _c("v-checkbox", {
                staticClass: "shrink ma-0",
                attrs: {
                  color: "#4A90E2",
                  "hide-details": "",
                  dense: "",
                  size: "sm",
                },
                model: {
                  value: _vm.selectedInternal,
                  callback: function ($$v) {
                    _vm.selectedInternal = $$v
                  },
                  expression: "selectedInternal",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pl-8 pr-8 pt-5 pb-5 border-bottom" },
        [
          _c(
            "v-row",
            { staticClass: "template-card__platforms" },
            [
              _c(
                "v-col",
                { staticClass: "pa-0 font-weight-bold", attrs: { md: "2" } },
                [_vm._v("Platforms")]
              ),
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { md: "10" } },
                _vm._l(_vm.platformTypeOptions, function (platform) {
                  return _c(
                    "v-chip",
                    {
                      key: platform.id,
                      staticClass: "pl-1 pr-1 ml-1",
                      attrs: {
                        color: platform.isSelected ? "#439ED5" : "#ccc",
                        label: "",
                        small: "",
                        dark: "",
                      },
                    },
                    [_vm._v(" " + _vm._s(platform.text) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "template-card__order-type pt-1" },
            [
              _c(
                "v-col",
                { staticClass: "pa-0 font-weight-bold", attrs: { md: "2" } },
                [_vm._v("Order type")]
              ),
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { md: "10" } },
                _vm._l(_vm.orderTypeOptions, function (orderType) {
                  return _c(
                    "v-chip",
                    {
                      key: orderType.id,
                      staticClass: "pl-1 pr-1 ml-1",
                      attrs: {
                        color: orderType.isSelected ? "#679436" : "#ccc",
                        label: "",
                        small: "",
                        outlined: "",
                      },
                    },
                    [_vm._v(" " + _vm._s(orderType.text) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pl-8 pr-8 pt-7 pb-5" },
        [
          _c(
            "v-row",
            { staticClass: "template-card__text" },
            [
              _c(
                "v-col",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-expansion-panels",
                    { attrs: { accordion: "" } },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v(" Header logo "),
                          ]),
                          _c("v-expansion-panel-content", [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.receipt.headerLogoHtml),
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v(
                              "Template file: " +
                                _vm._s(_vm.receipt.templateFile)
                            ),
                          ]),
                          _c("v-expansion-panel-content", [
                            _c("iframe", {
                              attrs: {
                                width: "100%",
                                height: "300px",
                                src:
                                  "https://grabmobilestagev2.com/cursusairportinformation/EmailTemplate/" +
                                  _vm.receipt.templateFile,
                                frameborder: "0",
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v("Thank you message"),
                          ]),
                          _c("v-expansion-panel-content", [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.receipt.thankYouMessageHtml
                                ),
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v("Email subject template"),
                          ]),
                          _c("v-expansion-panel-content", [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.receipt.emailSubjectTemplate
                                ),
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v("Template description"),
                          ]),
                          _c("v-expansion-panel-content", [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.receipt.templateDescription
                                ),
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-actions",
        {
          staticClass: "d-flex pr-5 pl-5 pt-3 align-center mt-auto border-top",
        },
        [
          _c("v-spacer"),
          _vm.receipt.isDefaultTemplate
            ? _c(
                "div",
                {
                  staticClass:
                    "template-card__actions grey--text text-uppercase pr-2",
                },
                [_vm._v(" default ")]
              )
            : _vm.restoreConfirmation
            ? _c(
                "div",
                [
                  _c(
                    "span",
                    { staticClass: "template-card__reset-confirmation" },
                    [_vm._v(" Are you sure? ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { "x-small": "", text: "", color: "#679436" },
                      on: {
                        click: function ($event) {
                          return _vm.reset()
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { "x-small": "" } }, [
                        _vm._v("check"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { "x-small": "", text: "", color: "#f00" },
                      on: {
                        click: function ($event) {
                          _vm.restoreConfirmation = false
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { "x-small": "" } }, [
                        _vm._v("close"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-btn",
                {
                  attrs: { "x-small": "", text: "", color: "#84B7D3" },
                  on: {
                    click: function ($event) {
                      _vm.restoreConfirmation = true
                    },
                  },
                },
                [
                  _vm._v(" restore to default "),
                  _c("v-icon", { attrs: { "x-small": "" } }, [
                    _vm._v("restore"),
                  ]),
                ],
                1
              ),
          _c("v-divider", {
            staticClass: "mt-1 mb-1 ml-1 mr-1",
            attrs: { vertical: "" },
          }),
          _c(
            "v-btn",
            {
              attrs: { color: "#679436", "x-small": "", text: "" },
              on: {
                click: function ($event) {
                  return _vm.edit()
                },
              },
            },
            [_c("v-icon", { attrs: { small: "" } }, [_vm._v("edit")])],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }