var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "template-card-base",
    _vm._g(
      _vm._b(
        {
          attrs: {
            receipt: _vm.receipt,
            orderStatusLabel: _vm.orderStatusLabelById(_vm.receipt.orderStatus),
            orderTypeLabel: _vm.orderTypeLabelById(_vm.receipt.orderType),
            platformTypeLabel: _vm.platformLabelById(_vm.receipt.platformType),
            waypointLabel: _vm.waypointLabel,
            selected: _vm.selected,
            "show-actions": _vm.showActions,
            waypointId: _vm.waypointId,
            "platform-types": _vm.platformTypes,
            "order-types": _vm.orderTypes,
          },
        },
        "template-card-base",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }